<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" data-projection-id="9" style="opacity: 1">
        <div data-testid="hero-video-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <div class="relative w-full h-full overflow-hidden max-h-[80vh] h-[520px] md:h-[650px] md:max-h-full w-full">
            <div autoplay="" style="width: 100%; height: 100%">
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b1.png')" alt="" />
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-700 md:text-iblack-300 dark:text-iblack-300 max-w-[500px]">
                Convening for<br class="hidden md:inline" />
                Greater Impact
              </h1>
              <p class="text-lg text-iblack-600 md:text-iblack-400 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                We convene our extraordinary community of families,<br class="hidden md:inline" />
                founders, and organizations to catalyze collaborative<br class="hidden md:inline" />
                philanthropy for giving at scale. Together, we tackle<br class="hidden md:inline" />
                the world’s most urgent challenges, with the goal of<br class="hidden md:inline" />
                creating a more just and equitable world.
              </p>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div
              role="separator"
              aria-orientation="horizontal"
              class="w-full h-full block"
              data-projection-id="10"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="flex items-center main-grid--in-grid justify-center">
          <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
            <div class="col-span-6">
              <div
                class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-center"
                data-projection-id="11"
                style="   transform: translateY(20px) translateZ(0px)"
              >
                <div class="text-center">
                  <h1 class="my-[30px] font-medium">Join Our Collaborative Initiatives</h1>
                  <p class="m-0"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="12">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="13"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b2.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="14"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Climate Equity<br class="hidden md:inline" />
                            Co-Lab
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="15"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            A $50 million philanthropic fund supporting organizations at the intersection of climate
                            change, gender equity, and poverty.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact/co-labs"
                    ><section class="" data-projection-id="16">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="17"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="18"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Education &amp; Economic Mobility<br class="hidden md:inline" />
                            Co-Lab
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="19"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            A $50 million philanthropic fund mobilizing capital to provide equitable education and
                            career pathways for all students in the United States.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact/co-labs"
                    ><section class="" data-projection-id="20">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="21"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b4.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="22"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Ocean<br class="hidden md:inline" />
                            Co-Lab
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="23"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            A $50 million philanthropic fund supporting solutions that will protect our ocean, and our
                            planet, from a climate disaster.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="24">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="25"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b5.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="26"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Larsen Lam ZEN CAPITAL Impact Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="27"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            A $24 million grant competition supporting organizations improving the lives of refugees
                            around the world.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="28">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="29"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b6.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="30"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Stronger Democracy Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="31"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            A $22 million grant competition to help improve political representation and increase
                            participation in the United States’ democratic process.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="32"
            style="background-color: rgb(155, 155, 155);    transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div
          class="relative transition-opacity duration-300 overflow-hidden main-grid--in-grid"
          data-projection-id="76"
          style="   transform: translateY(40px) translateZ(0px)"
        >
          <div
            data-testid="tout-video-bg"
            class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
          >
            <div class="relative w-full h-full overflow-hidden max-h-[80vh] min-h-[100%] w-full">
              <div autoplay="" style="width: 100%; height: 100%">
                <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b7.png')" alt="" />
              </div>
            </div>
          </div>
          <div
            class="md:h-[550px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[550px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
            >
              <div
                class="mt-[30px] md:my-0"
                data-projection-id="77"
                style="   transform: translateY(20px) translateZ(0px)"
              >
                <h1 class="text-2xl text-iblack-700 md:text-iblack-300 dark:text-iblack-300 md:dark:text-iblack-300">
                  Total Grants Advised on: $468M
                </h1>
                <p
                  class="text-lg text-iblack-600 dark:text-iblack-600 md:dark:text-iblack-400 md:text-iblack-400 mt-2.5 max-w-[500px]"
                >
                  Since April 2019, ZEN CAPITAL Impact has advised<br class="hidden md:inline" />
                  on nearly half a billion in philanthropic capital.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="35"
            style="background-color: rgb(155, 155, 155);    transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div
          class="relative transition-opacity duration-300 overflow-hidden col-start-1 col-span-full"
          data-projection-id="78"
          style="transform: translateY(40px) translateZ(0px)"
        >
          <div
            data-testid="tout-video-bg"
            class="col-start-1 col-span-full md:h-[100%] w-[100vw] top-0 left-0 z-[-1] overflow-hidden"
          >
            <div class="relative w-full h-full overflow-hidden max-h-[80vh] min-h-[100%] w-full">
              <div autoplay="" style="width: 100%; height: 100%">
                <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b8.png')" alt="" />
              </div>
            </div>
          </div>
          <div
            class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:absolute md:top-0 md:left-0 md:w-full"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-2 col-span-15 md:col-start-2 lg:col-start-2 justify-start mt-[30px] md:mt-[40px] mb-[30px] md:my-0"
            >
              <div class="relative flex flex-col justify-between md:h-full my-0">
                <div>
                  <h1 class="text-2xl text-iblack-700 dark:text-iblack-300 md:dark:text-iblack-700">Global Grants</h1>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="81"
            style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="main-grid--in-grid">
                <h1
                  class="text-iblack-700 dark:text-iblack-300 mb-30 text-lg md:text-2xl"
                  data-projection-id="40"
                  style="   transform: translateY(20px) translateZ(0px)"
                >
                  Our Model for Collaborative Philanthropy
                </h1>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <section class="" data-projection-id="41">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="42"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b9.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-6">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          data-projection-id="43"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Convene
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="44"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          We connect founders, entrepreneurs, and philanthropists from our community who have a shared
                          social impact goal.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <section class="" data-projection-id="45">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="46"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b10.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-6">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          data-projection-id="47"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Collaborate
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="48"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Together, we consult with strategic partners and subject-matter experts to build a deep
                          understanding of social problems. We then work with world-class diligence partners to identify
                          and vet high-impact organizations working on proven solutions.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <section class="" data-projection-id="49">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="50"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b11.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-6">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          data-projection-id="51"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Co-fund Change
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="52"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Through collaboration and pooled funding models, we help our clients deploy their
                          philanthropic capital effectively.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="53"
            style="background-color: rgb(155, 155, 155);    transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <a
          draggable="false"
          target="_self"
          class="main-grid--in-grid transition-opacity duration-500 hover:opacity-80 focus:opacity-80"
          href="/impact/co-labs"
          ><div
            class="relative transition-opacity duration-300 overflow-hidden"
            data-projection-id="82"
            style="   transform: translateY(40px) translateZ(0px)"
          >
            <div
              data-testid="tout-video-bg"
              class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
            >
              <div class="relative w-full h-full overflow-hidden max-h-[80vh] min-h-[100%] w-full">
                <div autoplay="" style="width: 100%; height: 100%">
                  <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b12.png')" alt="" />
                </div>
              </div>
            </div>
            <div
              class="md:h-[550px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[550px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
            >
              <div
                class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
              >
                <div
                  class="mt-[30px] md:my-0"
                  data-projection-id="83"
                  style="   transform: translateY(20px) translateZ(0px)"
                >
                  <h1 class="text-2xl text-iblack-700 md:text-iblack-700 dark:text-iblack-300 md:dark:text-iblack-300">
                    Ocean Co-Lab
                  </h1>
                  <p
                    class="text-lg text-iblack-600 dark:text-iblack-600 md:dark:text-iblack-400 md:text-iblack-700 mt-2.5 max-w-[500px]"
                  >
                    A $50 million philanthropic fund supporting solutions that will protect our ocean, and our planet,
                    from a climate disaster.
                  </p>
                </div>
              </div>
            </div>
          </div></a
        >
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="56"
            style="background-color: rgb(155, 155, 155);    transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="main-grid--in-grid">
                <h1
                  class="text-iblack-700 dark:text-iblack-300 mb-30 text-lg md:text-2xl"
                  data-projection-id="57"
                  style="   transform: translateY(20px) translateZ(0px)"
                >
                  Insights
                </h1>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="58">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="59"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b13.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="60"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            New $10 Million Award Launched to Improve Maternal and Infant Health Outcomes around the
                            World
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="61"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            The Maternal &amp; Infant Health Award is a $10 million grant competition to help improve
                            maternal and infant health outcomes across<br class="hidden md:inline" />
                            the globe.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-8 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="62">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="63"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b14.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="64"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Five Outstanding Organizations Working to Save Democracy in The United States
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="65"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            The Stronger Democracy Award is a $22M grant competition to help improve political
                            representation and increase participation in the United States’ democratic process. We’re
                            thrilled to introduce the five finalists.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-14 relative">
                <div>
                  <section class="" data-projection-id="66">
                    <div
                      class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                      data-projection-id="67"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b15.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-6">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                          data-projection-id="68"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Finalists Selected for $22 Million<br class="hidden md:inline" />
                          Award to Strengthen Democracy in<br class="hidden md:inline" />
                          The United States
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="69"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          The Stronger Democracy Award is a $22M grant competition to help improve political
                          representation and increase participation in the United States’ democratic process.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-5 col-start-20 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="70">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="71"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impact/b16.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="72"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Harnessing the Untapped Power of Female Workers to Enhance Climate Resiliency
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="73"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            To celebrate the launch of the ZEN CAPITAL Impact Climate Equity Co-Lab, we're highlighting the
                            three portfolio organizations helping women protect their lives and our planet through
                            climate-related employment.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
